.card {
    position: relative;
    width: 190px;
    height: 254px;
    background-color: #000;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 12px;
    gap: 12px;
    border-radius: 8px;
    cursor: pointer;
    color: white;
    margin: 55px;
}

.card::before {
    content: '';
    position: absolute;
    inset: 0;
    left: -5px;
    margin: auto;
    width: 200px;
    height: 264px;
    border-radius: 10px;
    background: linear-gradient(-45deg, #ff0099 0%, #000c3f 100%);
    z-index: -10;
    pointer-events: none;
    transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.card::after {
    content: "";
    z-index: -1;
    position: absolute;
    inset: 0;
    background: linear-gradient(-45deg, #ff0099 0%, #000c3f 100%);
    transform: translate3d(0, 0, 0) scale(0.95);
    filter: blur(20px);
}

.heading {
    font-size: 15px;
    text-transform: uppercase !important;
    font-weight: 500;
}

.card p:not(.heading) {
    font-size: 13px;
}

.card p:last-child {
    color: #e81cff;
    font-weight: 600;
}

.card:hover::after {
    filter: blur(30px);
}

.card:hover::before {
    transform: rotate(-90deg) scaleX(1.34) scaleY(0.77);
}

a {
    text-decoration: none !important;
}


@media (min-width: 421px) and (max-width: 895px) {
    .card {
        width: 160px;
        height: 230px;
        margin: 20px;
        padding: initial;
    }

    .card::before {
        width: 160px;
        height: 220px;
        padding: initial;

    }

    p {
        font-size: 13px !important;
        margin-left: 5px;
    }
}

@media (min-width: 894px) and (max-width: 1190px) {
    .card {
        width: 180px;
        height: 220px;
        margin: 20px;
        padding: initial;
    }
}



