body {
    margin: 0;
    padding: 0;
    height: 100vh;
    color: #ffffff;
    background: #000000;
    font-family: Oswald, monospace !important;
}

h2 {
    font-size: 3.8vw;
    font-weight: 400;
    text-align: center;
    justify-content: center;
    text-transform: uppercase;
    letter-spacing: 20px;
    text-shadow: 0 0 30px rgba(0, 0, 0, 0.9);
    position: relative;
    z-index: 2;
    margin-top: 10%;
}

section {
    height: 100%;
    width: 100%;
    top: 0;
    position: fixed;
    visibility: hidden;
}

section .outer,
section .inner {
    width: 100%;
    height: 100%;
    overflow-y: hidden;
}

section .bg {
    display: grid;
    place-items: center;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    background-size: cover;
    background-position: center;
}

section .bg::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1;
}

.icons {
    z-index: 2;
    margin-bottom: 200px;

    & svg {
        height: 100px;
        width: 100px;
        margin-right: 50px;
    }
}

.me-img {
    border-radius: 50%;
    width: 400px;
    height: 400px;
}

.card-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

@media (max-width: 1024px) {

    h2 {
        font-size: 5vw;
        margin-top: 10%;
    }

    .icons svg {
        height: 80px;
        width: 80px;
        margin-right: 25px;
    }

    .me-img {
        width: 200px;
        height: 200px;
    }
}

@media (max-width: 768px) {
    /* Styles for mobile devices */
    h2 {
        font-size: 9vw;
        margin-top: 150px;
        letter-spacing: 13px;
    }

    .icons svg {
        height: 80px;
        width: 80px;
        margin-right: 20px;
        margin-bottom: 100px;
    }

    .me-img {
        width: 200px;
        height: 200px;
        margin-bottom: 20%;
    }
}

@media (max-width: 520px) {
    h2 {
        font-size: 9vw;
        margin-top: 150px;
        letter-spacing: 13px;
    }

    .icons svg {
        height: 50px;
        width: 50px;
        margin-right: 20px;
        margin-bottom: 100px;
    }

    .me-img {
        width: 200px;
        height: 200px;
        margin-bottom: 20%;
    }
}




