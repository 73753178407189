* {
    box-sizing: border-box;
}

body {
    font-family: Oswald, monospace;
    color: #f9f9f9;
}

button {
    border: 0;
    padding: 0;
    background: transparent;
    cursor: pointer;
}

.swiper {
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.swiper-slide {
    display: grid;
    place-items: center;
    opacity: 0;
    transition: 0.25s;
}

.swiper-slide-active {
    opacity: 1;
}

.swiper-slide > div {
    margin-top: -73px;
}

.swiper-slide h2 {
    opacity: 0.35;
    font-weight: 400;
    width: 100%;
}

.swiper-button-next,
.swiper-button-prev {
    opacity: 0.5;
    color: rgb(255 255 255 / 96%);
    transition: 0.3s;
}

:is(.swiper-button-next, .swiper-button-prev):hover {
    opacity: 1;
}

:is(.swiper-button-next, .swiper-button-prev).swiper-button-disabled {
    opacity: 0.1;
    cursor: default;
}

.swiper-button-next {
    right: 50px;
}

.swiper-button-prev {
    left: 50px;
}

.burger,
.menu,
.background {
    position: fixed;
}

.background {
    z-index: 1;
    top: -10%;
    left: -10%;
    width: 120%;
    height: 120%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transition: 0.5s;
}

.background.open {
    filter: blur(16px);
}

.burger {
    z-index: 3;
    top: 0;
    left: 0;
    display: grid;
    place-items: center;
    width: 88px;
    height: 88px;
    background-image: url("/src/components/menu/assets/menu.svg");
    background-size: 70px;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 10px;
    margin-top: 10px;
}

.burger.open {
    background-image: url("/src/components/menu/assets/close.svg");
}

.menu {
    z-index: 2;
    top: 0;
    left: 0;
    display: grid;
    place-items: center;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.95);
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;
}

.menu.open {
    opacity: 1;
    visibility: visible;
}

.menu nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.menu nav:hover a {
    opacity: 0.25;
}

.menu nav a:hover {
    opacity: 1;
}

.menu a {
    position: relative;
    color: #f9f9f9;
    font-size: 32px;
    padding: 20px 0;
    width: 260px;
    text-decoration: none;
    transition: 0.4s;
}

.menu a::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 10px;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background: #f7f7f7;
    transform: scaleX(0);
    transform-origin: 0% 50%;
    transition: 0.4s;
}

.menu a:hover::after {
    transform: scaleX(1);
}
/* Responsive styles */
@media (max-width: 768px) {
    /* Styles for tablets and smaller devices */
    .menu a {
        font-size: 24px;
        padding: 15px 0;
    }
    .burger {
        background-size: 60px;
    }
}

@media (max-width: 480px) {
    /* Styles for mobile devices */
    .menu a {
        font-size: 18px;
        padding: 10px 0;
    }
    .burger {
        background-size: 50px;
    }
}